import logo from "./images/Logo_Yellow_Transparent_Horizontal.gif";
import "./App.css";
import * as React from "react";
import ReactDOM from "react-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "@fontsource/roboto/400.css";

var red = "rgba(255,0,0,0.2)";
var blue = "rgba(0,0,255,0.2)";
var green = "rgba(0,255,0,0.2)";
red = "rgba(255,0,0,0)";
blue = "rgba(0,0,255,0)";
green = "rgba(0,255,0,0)";

let margin = "20px";

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Courier",
    },
    h2: {
      fontFamily: "Courier",
    },
    h3: {
      fontFamily: "Courier",
    },
    h4: {
      fontFamily: "Courier",
    },
    body: {
      fontFamily: "Courier",
      color: "white",
    },
    button: {
      fontFamily: "Courier",
    },
  },
  Link: {
    color: "#00ff00",
    "&:hover": {
      color: "#000000",
      textDecoration: "underline #000000",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "left",
}));

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Grid
          container
          spacing={0}
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item style={{ backgroundColor: red }}>
            {/* <Item>Top</Item> */}
          </Grid>

          <Grid
            container
            gap="40px"
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            paddingX="20px"
          >
            <Grid
              item
              style={{ backgroundColor: blue }}
              textAlign="center"
              sx={{ marginTop: { xs: "20px", sm: "40px" } }}
            >
              <img src={logo} className="App-logo" alt="logo" />
            </Grid>
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="top"
              display="flex"
              flexWrap="nowrap"
              sx={{
                backgroundColor: green,
                width: "100%",

                marginInline: "auto",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid item sx={{ textAlign: { xs: "center", sm: "left" } }}>
                <Typography variant="body" marginRight={"40px"}>
                  2022
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: { xs: "center", sm: "left" }, width: '100%', maxWidth: "528px" }}>
                {/* <Typography variant="body" display="block">
                  browsers.xyz – Swipe + Trade
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    href="#"
                  >
                    comming soon
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
                {/*<Grid item>-</Grid> */}
                <Typography variant="body" display="block">
                Pickle People – Community-driven Pickle Pixel Art 
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    href="https://picklepeople.io"
                  >
                     picklepeople.io
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
                <Grid item>-</Grid>
                <Typography variant="body" display="block">
                Grandma's Candies – small collection of regular candies
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    href="https://candies.shop"
                  >
                    candies.shop
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    href="https://opensea.io/collection/grandmas-candies"
                  >
                    Opensea
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    href="https://etherscan.io/address/0x832de117d8fa309b55f9c187475a17b87b9dfc85"
                  >
                    Etherscan
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
                <Grid item>-</Grid>
                <Typography variant="body" display="block">
                  Regslist.org – The most down-to-earth web3 classifieds
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    href="https://regslist.org"
                  >
                    regslist.org
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    href="https://twitter.com/regslist"
                  >
                    twitter
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
                <Grid item>-</Grid>
                <Typography variant="body" display="block">
                  Regular Jobs - Put your regs to work and earn tokens
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    href="https://regular.world/faq"
                  >
                    FAQ
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    href="https://opensea.io/collection/regular-jobs"
                  >
                    Opensea
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    href="https://regular.world"
                  >
                    regular.world
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
                <Grid item>-</Grid>
                <Typography variant="body" display="block">
                  Regular names + On-chain metadata
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    href="https://etherscan.io/address/0x1FFE4026573cEad0F49355b9D1B276a78F79924F#code"
                  >
                    Etherscan
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
                <Grid item>-</Grid>
                <Typography variant="body" display="block">
                  $REG - ERC20 / ERC1363 Token
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    href="https://etherscan.io/address/0x78b5C6149C87c82EDCffC73C230395abbc56DdD5#code"
                  >
                    Etherscan
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
                <Grid item>-</Grid>
                <Typography variant="body" display="block">
                Security for Discord Servers by Monharti
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    href="https://docs.mustardlabs.io/security-for-discord-servers/"
                  >
                    Mustard Labs Docs
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="top"
              display="flex"
              flexWrap="nowrap"
              sx={{
                backgroundColor: green,
                width: "100%",
                marginInline: "auto",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid item sx={{ textAlign: { xs: "center", sm: "left" } }}>
                <Typography variant="body" marginRight={"40px"}>
                  2021
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: { xs: "center", sm: "left" } }}>
                <Typography variant="body" display="block">
                  The Regulars – An extra-ordinary 10k pfp project
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    href="https://regular.world"
                  >
                    regular.world
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    href="https://opensea.io/collection/regulars"
                  >
                    Opensea
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    href="https://etherscan.io/address/0x6d0de90CDc47047982238fcF69944555D27Ecb25#writeProxyContract#code"
                  >
                    Etherscan
                  </Link>
                </Box>
                <Grid item></Grid>
                {/* <Typography variant="body"> </Typography> */}
                <Grid item>-</Grid>
                <Typography variant="body">
                 swap.regular.world – Regular trading
                </Typography>
                <Grid item></Grid>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    style={{ marginRight: margin }}
                    href="https://swap.regular.world"
                  >
                    swap.regular.world
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    style={{ marginRight: margin }}
                    href=""
                  >
                    Etherscan
                  </Link>
                </Box>
                <Grid item>-</Grid>
                <Typography variant="body">
                  Ugly Pool – Simple 1:1 trades
                </Typography>
                <Grid item></Grid>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                  sx={{ justifyContent: { xs: "center", sm: "left" } }}
                >
                  <Link
                    variant="body"
                    target="_new"
                    style={{ marginRight: margin }}
                    href="https://uglypool.xyz"
                  >
                    uglypool.xyz
                  </Link>
                  <Link
                    variant="body"
                    target="_new"
                    style={{ marginRight: margin }}
                    href="https://etherscan.io/address/0xc2760aEd8BD6dd45676Ca565Cd5eAcbB2803987a#code"
                  >
                    Etherscan
                  </Link>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ backgroundColor: red }}
            >
              <Grid item></Grid>
              <Grid item>
                <Typography variant="body">Team</Typography>
              </Grid>
              <Grid item></Grid>
              <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="top"
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="top"
                  alignItems="center"
                  xs="auto"
                  margin={margin}
                  textAlign="center"
                  style={{ backgroundColor: green }}
                >
                  <Link
                    variant="body"
                    href="https://twitter.com/p0pps"
                    target="new"
                    borderBottom="0px !important"
                  >
                    <Avatar
                      alt="The image"
                      src={"./images/team/p0pps.jpg"}
                      style={{ width: 80, height: 80 }}
                    />
                  </Link>
                  <Grid item marginBottom={"6px"} marginTop={"10px"}>
                    <Link
                      variant="body"
                      href="https://twitter.com/p0pps"
                      target="new"
                    >
                      p0pps
                    </Link>
                  </Grid>
                  <Typography variant="body">Founder/</Typography>
                  {/* <Typography  variant="body">+</Typography> */}
                  <Typography variant="body">Creative</Typography>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="top"
                  alignItems="center"
                  xs="auto"
                  margin={margin}
                  textAlign="center"
                  style={{ backgroundColor: green }}
                >
                  <Link
                    variant="body"
                    href="https://github.com/immunity20"
                    target="new"
                    borderBottom="0px !important"
                  >
                    <Avatar
                      alt="The image"
                      src={"./images/team/immunity.jpg"}
                      style={{ width: 80, height: 80 }}
                    />
                  </Link>
                  <Grid item marginBottom={"6px"} marginTop={"10px"}>
                    <Link
                      variant="body"
                      href="https://github.com/immunity20"
                      target="new"
                    >
                      iMMuNiTy
                    </Link>
                  </Grid>
                  <Typography variant="body">Front-end</Typography>
                  <Typography variant="body">Dev</Typography>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="top"
                  alignItems="center"
                  xs="auto"
                  margin={margin}
                  textAlign="center"
                  style={{ backgroundColor: green }}
                >
                  <Link
                    variant="body"
                    href="https://github.com/Amxx"
                    target="new"
                    borderBottom="0px !important"
                  >
                    <Avatar
                      alt="The image"
                      src={"./images/team/amxx.jpg"}
                      style={{ width: 80, height: 80 }}
                    />
                  </Link>
                  <Grid item marginBottom={"6px"} marginTop={"10px"}>
                    <Link
                      variant="body"
                      href="https://github.com/Amxx"
                      target="new"
                    >
                      Amxx
                    </Link>
                  </Grid>
                  <Typography variant="body">Contracts/</Typography>
                  <Typography variant="body">Advisor</Typography>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="top"
                  alignItems="center"
                  xs="auto"
                  margin={margin}
                  textAlign="center"
                  style={{ backgroundColor: green }}
                >
                  <Link
                    variant="body"
                    href="https://twitter.com/monharti"
                    target="new"
                    borderBottom="0px !important"
                  >
                    <Avatar
                      alt="The image"
                      src={"./images/team/monharti.jpg"}
                      style={{ width: 80, height: 80 }}
                    />
                  </Link>
                  <Grid item marginBottom={"6px"} marginTop={"10px"}>
                    <Link
                      variant="body"
                      href="https://twitter.com/monharti"
                      target="new"
                    >
                      Monharti
                    </Link>
                  </Grid>
                  <Typography variant="body">Community</Typography>
                  {/* <Typography  variant="body">+</Typography> */}
                  <Typography variant="body">Manager</Typography>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="top"
                  alignItems="center"
                  xs="auto"
                  margin={margin}
                  textAlign="center"
                  style={{ backgroundColor: green }}
                >
                  <Link
                    variant="body"
                    href="https://twitter.com/uuuteki"
                    target="new"
                    borderBottom="0px !important"
                  >
                    <Avatar
                      alt="The image"
                      src={"./images/team/uteki.jpg"}
                      style={{ width: 80, height: 80 }}
                    />
                  </Link>
                  <Grid item marginBottom={"6px"} marginTop={"10px"}>
                    <Link
                      variant="body"
                      href="https://twitter.com/uuuteki"
                      target="new"
                    >
                      Uteki
                    </Link>
                  </Grid>
                  <Typography variant="body">UX Design</Typography>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="top"
                  alignItems="center"
                  xs="auto"
                  margin={margin}
                  textAlign="center"
                  style={{ backgroundColor: green }}
                >
                  <Link
                    variant="body"
                    href="https://twitter.com/HAMscale"
                    target="new"
                    borderBottom="0px !important"
                  >
                    <Avatar
                      alt="The image"
                      src={"./images/team/apaul.png"}
                      style={{ width: 80, height: 80 }}
                    />
                  </Link>
                  <Grid item marginBottom={"6px"} marginTop={"10px"}>
                    <Link
                      variant="body"
                      href="https://twitter.com/HAMscale"
                      target="new"
                    >
                      Apaul
                    </Link>
                  </Grid>
                  <Typography variant="body">Growth</Typography>
                </Grid>
              </Grid>

            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="top"
              gap="40px"
              style={{ backgroundColor: green, width: "100%", marginBlock: "20px" }}
            >
              <Grid item xs="auto">
                {/* <Typography variant="body">discord.gg/EqHZJztTRz</Typography> */}
                <Link
                  variant="body"
                  href="https://discord.gg/wveCyAeTsm"
                  target="new"
                >
                  discord.gg/EqHZJztTRz
                </Link>
              </Grid>
              <Grid item xs="auto">
                <Link
                  variant="body"
                  href="https://twitter.com/Mustard_Labs"
                  target="new"
                >
                  twitter.com/mustard_labs
                </Link>
              </Grid>
              <Grid item xs="auto">
                <Link
                  variant="body"
                  href="mailto:p0pps@regular.world"
                  target="new"
                >
                  Contact for inqueries.
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>{/* <Item>Bottom</Item>  */}</Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default App;
